import React from 'react';
import { Contact } from '../components/page-wrappers';
import Google from '../assets/svg/google.svg';
import Yelp from '../assets/svg/yelp.svg';
import Instagram from '../assets/svg/instagram.svg';
import Facebook from '../assets/svg/facebook.svg';
import TripAdvisor from '../assets/svg/tripadvisor.svg';
import { Helmet } from 'react-helmet';

// tslint:disable no-default-export
export default () => {
  return (
    <>
      <Helmet title="Ruadeseo | Contact Us">
        {' '}
        <link rel="canonical" href="https://www.valledeguadalupewinetours.com/contact" />
      </Helmet>
      <Contact
        social={[
          { icon: <Google />, to: 'https://g.page/r/CVUTH963p9IBEAE' },
          { icon: <Yelp />, to: 'https://www.yelp.com/biz/ruadeseo-san-diego-3' },
          { icon: <Instagram />, to: 'https://www.instagram.com/ruadeseo' },
          { icon: <Facebook />, to: 'https://www.facebook.com/Ruadeseo-102230698813036' },
          {
            icon: <TripAdvisor />,
            to:
              'https://www.tripadvisor.com/Attraction_Review-g60750-d23461328-Reviews-Ruadeseo-San_Diego_California.html',
          },
        ]}
      />
    </>
  );
};
